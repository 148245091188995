import React from "react";
import { Content } from "../../components/content";
import { H1, H2, H4 } from "../../components/text";
import { LinkButton } from "../../components/button";
import { Hero } from "../../components/hero";
import { Layout } from "../../components/layout";
import { Seo } from "../../components/seo";
import { Box } from "../../components/box";
import { Spacer } from "../../components/spacer";
import { ImageGallery } from "../../components/image-gallery";
import { TabBox } from "../../components/tab-box";
import { ApplicationTypeTab } from "../../components/application-type-tab";
import { ProjectDates } from "../../components/project-dates";
import * as Styles from "./project.styles";
import { Accordion } from "../../components/accordion";
import { InlineLink } from "../../components/link";

const getContent = (content) => {
  if (
    !content ||
    typeof content !== "string" ||
    content.replace(" ", "") === "" ||
    content.replace(" ", "") === "<p></p>"
  ) {
    return null;
  }

  const htmlRegex = /<[a-z]+>/;

  if (htmlRegex.test(content)) {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  }

  return (
    <Styles.PreformattedContentWrapper>
      {content}
    </Styles.PreformattedContentWrapper>
  );
};

const Section = ({ title, content }) => {
  const formattedContent = getContent(content);

  if (!formattedContent) return null;

  return (
    <>
      <Spacer height={"50px"} />
      <Accordion label={title} keepOpen={title === "Description"}>
        {formattedContent}
      </Accordion>
    </>
  );
};

export const Project = ({ project }) => {
  const imageUrls = project.images.map((image) => image.url);

  const firstImageUrl = imageUrls && imageUrls.length ? imageUrls[0] : null;

  const charityLink = project.supportedCharityWebsite ? (
    <InlineLink
      href={project.supportedCharityWebsite}
      rel="noopener noreferrer"
      target="_blank"
      title={project.supportedCharityName}
    >
      {project.supportedCharityName}
    </InlineLink>
  ) : null;

  const tabs = [];
  const tabHeaders = [];
  const participantTypes = [
    { name: "Vet", type: "vet" },
    { name: "Vet Nurse", type: "vet_nurse" },
    { name: "Vet Student", type: "vet_student" },
    { name: "Vet Nurse Student", type: "vet_nurse_student" },
    { name: "Volunteer", type: "volunteer" },
  ];

  participantTypes.forEach((volunteer, i) => {
    let cost = project.costs.find(
      (cost) => cost.type === volunteer.type && cost.costText
    );

    if (!cost) {
      return;
    }

    tabs.push(
      <ApplicationTypeTab
        fullyBooked={project.fullyBooked && project.fullyBooked[volunteer.type]}
        cost={cost.costText}
        additionalCosts={project.additionalCosts}
        country={project.country}
        key={i}
      />
    );
    tabHeaders.push(volunteer.name);
  });

  return (
    <Layout>
      <Seo lang="en" title={project.name} image={firstImageUrl} />
      <Hero image={firstImageUrl}>
        <H1>{project.name}</H1>
      </Hero>
      <Content>
        <Styles.Row>
          <Styles.LeftColumn>
            <Box>
              <H2>Description</H2>
              <Spacer height={[2]} />
              {getContent(project.description)}
            </Box>
            <Spacer height="50px" />
            <ImageGallery images={imageUrls} alt={project.name} />
          </Styles.LeftColumn>
          <Spacer width={[0, 0, "50px"]} height={["50px", "50px", 0]} />
          <Styles.RightColumn>
            <TabBox tabs={tabHeaders} variant="horizontal">
              {tabs}
            </TabBox>
            <Spacer height={["50px", "50px", "50px"]} />
            <Box variant="secondary">
              <H4>Country</H4>
              <p>{project.country}</p>
              {charityLink && (
                <>
                  <H4>Supported Charity</H4>
                  <p>{charityLink}</p>
                </>
              )}
              {project.trips && project.trips.length ? (
                <>
                  <ProjectDates trips={project.trips} />
                  <Spacer height={[2, 2, "50px"]} />
                  <Styles.ButtonWrapper>
                    <LinkButton
                      to={`${process.env.GATSBY_BASECAMP_URL}/travel/${project.slug}`}
                    >
                      Apply now
                    </LinkButton>
                  </Styles.ButtonWrapper>
                </>
              ) : project.usePlaceholderContent &&
                project.placeholderContent ? (
                <>
                  <H4>Application info</H4>
                  <p>{getContent(project.placeholderContent)}</p>
                </>
              ) : null}
            </Box>
          </Styles.RightColumn>
        </Styles.Row>
        <Section title="Itinerary" content={project.itinerary} />
        <Section title="Accommodation" content={project.accommodation} />
        <Section title="Requirements" content={project.requirements} />
        <Section
          title="Health and Vaccinations"
          content={project.healthAndVaccinations}
        />
        <Section
          title="Visas and travel insurance"
          content={project.travelInsuranceAndVisas}
        />
        <Section title="What to pack" content={project.packingChecklist} />
        <Section
          title="Regional safety information"
          content={project.regionalSafetyInformation}
        />
        <Section
          title="Travel arrangements"
          content={project.travelArrangements}
        />
        <Section title="Notes" content={project.notes} />
      </Content>
    </Layout>
  );
};
